<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="article" v-for="count in data.records" :key="count.id" @click="toDetails(count.id)">
          <div class="date">
            <div class="day">{{count.day}}</div>
            <div class="year">{{count.month}} {{count.year}}</div>
          </div>
          <div class="content">
            <h4>{{count.title}}</h4>
            <p>{{count.brief}}</p>
           <div class="last">
             <p>来源：{{count.source}}</p>
             <a>阅读全文-></a>
           </div>
          </div>
        </div>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <card ref="card1">
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import pagination from "@/components/public/pagination";

export default {
  name: "training",
  components: {
    leftAndRight,
    card,
    pagination
  },
  data() {
    return {
      data:{},
      current:1
    }
  },
  mounted() {
    this.getDataList()
  },
  methods:{

    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '培训活动',
          belong: 1,
          current: this.current,
          size: 5
        }
      }).then((res) => {
        const monthEnglish=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"]

        let forms=res.data.records
        for(let i in forms){
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].year=this.$constContent.dateFormat("YYYY", date)
          forms[i].month=monthEnglish[date.getMonth()+1]
          forms[i].day=this.$constContent.dateFormat('dd',date)

        }
        this.data=res.data
      })

      const ths = this
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '热门活动',
        }
      }).then((res) => {
        let forms = res.data

        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        let Cards = []
        let Card = {}
        Card.title = '热门活动'
        Card.id = 0
        Card.content = res.data
        Cards.push(Card)
        ths.$nextTick(() => {
          ths.$refs.card.init(Cards)
        })
      })
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '往期活动',
        }
      }).then((res) => {
        let forms = res.data

        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        let Cards = []
        let Card = {}
        Card.title = '往期活动'
        Card.id = 0
        Card.content = res.data
        Cards.push(Card)
        ths.$nextTick(() => {
          ths.$refs.card1.init(Cards)
        })
      })



    },
    toPage(e){
      this.current=e
      this.getDataList()
    },

    toDetails(id){
      this.$router.push('/trainingDetails?id='+id)
      this.$emit('getTitle','活动详情')
    }
  }
}
</script>

<style scoped>
.article {
  width: 98%;
  height: 220px;
  border-bottom: 1px solid #eeeeee;
  margin-right: 2%;
  margin-bottom: 20px;
}

.date {
  width: 20%;
  float: left;
  background-image: url("../../assets/image/backgroundColor.png");
  background-size: 100% 100%;
  color: #ffffff;
  cursor: pointer;
}

.content {
  width: calc(80% - 10px);
  float: left;
  margin-left: 10px;
}

.day {
  width: 100%;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
}

.year {
  width: 100%;
  margin: 3px auto;
  font-size: 8px;
  text-align: center;
  padding-bottom: 20px;
}
.article .content p {
  line-height: 24px;
  font-size: 14px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  cursor: pointer;
}

.content h4 {
  line-height: 25px;
  height: 50px;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer;
}
.last{
  display: flex;
  justify-content: space-between;
}
.last p{
  width: 40%;
  cursor: pointer;
}
.last a {
  width: 40%;
  display: block;
  margin: 14px 0;
  text-align: right;
  color: #ff3300;
  font-width: 600;
  font-size: 14px;
  cursor: pointer;
}

.search {
  width: 100%;
  height: 40px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>